import { useAuth0 } from "@auth0/auth0-react";
import { KeyboardArrowDown as DownIcon } from "@mui/icons-material";
import OrgIcon from '@mui/icons-material/Business';
import InfectionSafeIcon from '@mui/icons-material/FmdBad';
import {
  AppBar, Avatar, Box,
  Button, Container, CssBaseline, Grid, Menu,
  MenuItem,
  Toolbar
} from "@mui/material";
import * as React from 'react';
import { useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import "./HomeTabs/style.css";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import { fetchAssetTypes } from "../../lib/api/asset-types";
import { fetchAsset, fetchAssets, saveAsset } from "../../lib/api/assets";
import { fetchPeople, fetchPerson, savePerson } from "../../lib/api/people";
import { fetchRoleTypes } from "../../lib/api/role-types";
import { fetchTags } from "../../lib/api/tags";
import HomeTemplate from "../HomeTemplate/HomeTemplate";
import ApplicationSettings from "../MainContainer/ApplicationSettings/ApplicationSettings";
import InfectionControlConsequences from "../MainContainer/HomeTabs/InfectionControlConsequences";
import InfectionControlSummary from "../MainContainer/HomeTabs/InfectionControlSummary";
import InfectionControlSummaryMap from "../MainContainer/HomeTabs/InfectionControlSummaryMap";
import Reports from "../MainContainer/HomeTabs/Reports/Reports";
import ReportUnderConstruction from "../MainContainer/HomeTabs/Reports/ReportUnderConstruction";
import ShowOutstandingNotifications from "../MainContainer/HomeTabs/ShowOutstandingNotifications/ShowOutstandingNotifications";
import MyProfile from "../MainContainer/MyProfile/MyProfile";
import AssetEditor from "./AssetManagement/AssetEditor";
import AssetManagement from "./AssetManagement/AssetManagement";
import ManageCase from "./HomeTabs/ManageCase";
import PersonEditor from "./UserManagement/PersonEditor";
import PersonManagement from "./UserManagement/PersonManagement";

function MainContainer({
  sites,
  tags,
  signOut,
  currentLocation,
  setCurrentLocation,
  fetchSite,
}) {
  const navigate = useNavigate();
  const { user: auth0User } = useAuth0();
  const [logoUrl, setLogoUrl] = useState(null);
  const [middleNavbarImage, setMiddleNavbarImage] = useState(null);

  /* Location menu */

  const [locationMenuAnchorEl, setLocationMenuAnchorEl] = React.useState(null);
  const locationMenuOpen = Boolean(locationMenuAnchorEl);


  const handleLocationMenuClick = (event) => {
    setLocationMenuAnchorEl(event.currentTarget);
  };

  const handleLocationMenuClose = () => {
    setLocationMenuAnchorEl(null);
  };

  /* User menu */

  const [userMenuAnchorEl, setUserMenuAnchorEl] = React.useState(null);
  const userMenuOpen = Boolean(userMenuAnchorEl);

  const handleUserMenuClick = (event) => {
    setUserMenuAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setUserMenuAnchorEl(null);
  };

  const userMenuItems = [
    { label: "My Profile", url: "/my-profile", disabled: true },
  ];


  /* Org menu */

  const [anchorElOrgMenu, setAnchorElOrgMenu] = React.useState(null);

  const handleOpenOrgMenu = (event) => {
    setAnchorElOrgMenu(event.currentTarget);
  };

  const handleCloseOrgMenu = () => {
    setAnchorElOrgMenu(null);
  };

  const orgMenuItems = [
    { label: "Person Management", url: "/people", admin: true },
    { label: "Asset Management", url: "/assets", admin: true },
  ];



  const theme = createTheme({
    palette: {
      primary: {
        main: "#01c1d3",
        contrastText: "#fff",
      },
      secondary: {
        main: "#283555",
        contrastText: "#fff",
      },
    },
    components: {
      MuiToolbar: {
        styleOverrides: {
          dense: {
            height: 46,
            minHeight: 46,
          },
        },
      },
      MuiMenu: {
        styleOverrides: {
          list: {
            paddingTop: 0,
            paddingBottom: 0,
          },
        },
      },
    },
    overrides: {
      MuiButton: {
        containedInfo: {
          color: "#4D6879",
        },
      },
    },
  });

  const appBarStyles = {
    background: "#fff",
    color: "#283555",
    boxShadow: "0px 1px 10px 0px rgb(0 0 0 / 15%)",
    borderBottom: "1px solid #e3e3e3",
    display: "flex",
    flexWrap: "wrap",
    alignContent: "center",
    justifyContent: "center",
  };

  const careHomeLogoStyle = {
    height: "37px",
    width: "154px",
  };

  const NHSLogoStyle = {
    height: "auto",
    width: "121px",
  };

  const upperRightUserMenuStyle = {
    display: "inline-flex",
    alignItems: "center",
    color: "#283555",
  };

  const downCaretStyle = {
    color: "#919191",
  };

  const lowerNavDownCaretStyle = {
    marginRight: "-11px",
    marginLeft: "11px",
  };

  const currentLocationStyle = {
    margin: 5,
    color: "#283555",
    display: "inline-flex",
    alignItems: "center",
  };

  const currentLocationTextStyle = {
    marginLeft: 2,
  };

  const navbarUserInfo = {
    margin: 8,
    marginLeft: 12,
    marginRight: 20,
    lineHeight: "1.3",
  };

  const navbarUserInfoFirstLine = {
    fontWeight: 600,
    fontSize: 14,
  };

  const upperAvatarContainerStyle = {
    background: "#EAF0FF",
    borderRadius: 30,
    width: 40,
    height: 40,
    margin: 5,
  };

  const toolbarStyle = {
    height: 58,
    borderTopWidth: 1,
    borderTopColor: "#F2F2F2",
    borderRightStyle: "solid",
    borderRightWidth: 1,
    borderRightColor: "#F2F2F2",
    borderRightStyle: "solid",
    borderBottomWidth: 1,
    borderBottomColor: "#F2F2F2",
    borderBottomStyle: "solid",
  };

  const lastLowerTabStyle = {
    borderRightWidth: 0,
    borderRightColor: "#fff",
  };

  const lowerTabStyles = {
    overflow: "visible",
    position: "relative",
    display: "inline-flex",
    fontWeight: 600,
    height: 58,
    color: "#283555",
    paddingTop: 20,
    fontSize: "16px",
    textTransform: "none",
    fontSize: 15,
    letterSpacing: "-0.45px",
    borderRightWidth: 1,
    borderRightColor: "#F2F2F2",
    borderRightStyle: "solid",
    paddingRight: 24,
    paddingLeft: 24,
    minWidth: 0,
    minHeight: 0,
    paddingTop: 0,
    paddingBottom: 0,
  };

  const tabIconStyle = {
    color: "#283555",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    width: "28px",
    height: "28px",
    background: "#EAF0FF",
    borderRadius: "50%",
    marginRight: "8px",
    padding: "5px",
  };

  const upperToolbarStyle = {
    background: "#FFF",
    display: "inline-flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 0,
    borderRightWidth: 1,
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderColor: "#F2F2F2",
    borderStyle: "solid",
    color: "#283555",
    fontSize: 12,
  };

  const onAvatarError = (e) => {
    e.target.src = `${process.env.REACT_APP_S3_URL}/avatar.png`;
  };

  React.useEffect(() => {
    // TODO: Get client type from the API
    setLogoUrl(
      auth0User.isCareHome
        ? `${process.env.REACT_APP_S3_URL}/proxximos_safer_care.png`
        : `${process.env.REACT_APP_S3_URL}/nhs_logo.png`
    );
    // TODO: Get the logo from the API
    setMiddleNavbarImage(
      `${process.env.REACT_APP_S3_URL}/client_logos/${auth0User.clientId}/logo.png` // Add cachebuster to force refresh
    );
  }, []);



  return (
    <>
      <ThemeProvider theme={theme}>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <CssBaseline />
          <AppBar
            position="relative"
            sx={{
              display: "flex",
              background: "#1C54C1",
              color: "#fff",
              boxShadow: "none",
              zIndex: "1200",
            }}
          >
            <Toolbar style={upperToolbarStyle}>
              <div class="logocontainer">
                {logoUrl ? (
                  <a href="/" style={{ border: 0, outline: 0 }}>
                    <img
                      src={logoUrl}
                      style={
                        auth0User.isCareHome ? careHomeLogoStyle : NHSLogoStyle
                      }
                      alt="Proxximos"
                    />
                  </a>
                ) : (
                  <></>
                )}
              </div>

              <div class="center-navbar-image">

                {sites && currentLocation ? (
                  <div style={{ position: "relative" }}>
                    <Button
                      aria-controls={
                        locationMenuOpen ? "basic-menu" : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={locationMenuOpen ? "true" : undefined}
                      onClick={handleLocationMenuClick}
                    >
                      <div
                        id="location-menu-label"
                        style={currentLocationStyle}
                      >
                        <DownIcon style={downCaretStyle} />
                        <span style={currentLocationTextStyle}>
                          {currentLocation.name}
                        </span>
                      </div>
                    </Button>

                    <Menu
                      id="location-menu"
                      anchorEl={locationMenuAnchorEl}
                      open={locationMenuOpen}
                      onClose={handleLocationMenuClose}
                      onBlur={handleLocationMenuClose}
                      hideBackdrop
                      disableEnforceFocus
                      disablebackdropclick
                      disableScrollLock
                      disableAutoFocusItem
                      disableRestoreFocus
                      disablePortal
                      style={{
                        position: "absolute",
                        top: "58px",
                        left: 0,
                      }}
                      MenuListProps={{
                        "aria-labelledby": "location-menu-label",
                      }}
                      sx={{
                        "& .MuiMenu-list": {
                          paddingTop: 0,
                          paddingBottom: 0,
                        },
                        "& .MuiMenu-paper": {
                          top: "0px !important",
                          left: "0px !important",
                          maxHeight: "none",
                          maxWidth: "none",
                          display: "inline-flex",
                          position: "relative",
                          marginLeft: "0px !important",
                          marginTop: "-17px !important",
                        },
                      }}
                      className="location-menu nav-menu"
                    >
                      {sites.map((item) => {
                        return (
                          <MenuItem
                            key={item.id}
                            onClick={(event) => {
                              event.stopPropagation();
                              setCurrentLocation(item);
                              fetchSite(item.id);
                              handleLocationMenuClose();
                            }}
                            value={item.id}
                            className="location-menu-item nav-menu-item"
                          >
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Menu>
                  </div>
                ) : (
                  <></>
                )}
              </div>

              <div class="navbar-right">

                <Button
                  aria-controls={userMenuOpen ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={userMenuOpen ? "true" : undefined}
                  onClick={handleUserMenuClick}
                >
                  <div style={upperRightUserMenuStyle}>
                    <DownIcon style={downCaretStyle} />
                    <div style={upperAvatarContainerStyle}>
                      <Avatar
                        alt="My Profile"
                        src={auth0User.picture}
                        imgProps={{ onError: onAvatarError }}
                      />
                    </div>
                    <div style={navbarUserInfo}>
                      <div id="user-name" style={navbarUserInfoFirstLine}>
                        {auth0User.name || "Test Name Here"}
                      </div>
                    </div>
                  </div>
                </Button>
                <Menu
                  id="user-menu"
                  anchorEl={userMenuAnchorEl}
                  open={userMenuOpen}
                  onClose={handleUserMenuClose}
                  onBlur={handleUserMenuClose}
                  hideBackdrop
                  disableEnforceFocus
                  disablebackdropclick
                  disableScrollLock
                  disableAutoFocusItem
                  disableRestoreFocus
                  disablePortal
                  style={{
                    position: "absolute",
                    top: "58px",
                    left: 0,
                  }}
                  MenuListProps={{
                    "aria-labelledby": "user-menu-label",
                  }}
                  sx={{
                    "& .MuiMenu-list": {
                      paddingTop: 0,
                      paddingBottom: 0,
                    },
                    "& .MuiMenu-paper": {
                      top: "8px !important",
                      maxHeight: "none",
                      maxWidth: "none",
                      display: "inline-flex",
                      position: "relative",
                      marginLeft: "10px !important",
                    },
                  }}
                  className="user-menu nav-menu"
                >
                  {userMenuItems.map((item) => {
                    return (
                      // ((item["admin"] && isAdmin) || !item["admin"]) && (
                      <MenuItem
                        onClick={(event) => {
                          event.stopPropagation();
                          navigate(item.url);
                          handleUserMenuClose();
                        }}
                        disabled={item.disabled}
                        className="user-menu-item nav-menu-item"
                      >
                        {item.label}
                      </MenuItem>
                      // )
                    );
                  })}
                  <MenuItem
                    onClick={(event) => {
                      event.stopPropagation();

                      signOut();
                    }}
                    className="user-menu-item nav-menu-item"
                  >
                    Sign out
                  </MenuItem>
                </Menu>
              </div>
            </Toolbar>
          </AppBar>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <AppBar position="relative" sx={appBarStyles}>
                <Toolbar sx={toolbarStyle} variant="dense">

                  <Box sx={{ flexGrow: 1, display: 'flex' }}>
                    <Button
                      key="home"
                      onClick={() => {
                        navigate("/");
                      }}
                      sx={{ my: 2, display: 'block' }}
                      style={{ ...lowerTabStyles }}
                    >
                      <InfectionSafeIcon sx={{ ...tabIconStyle }} />
                      Infection Safe
                    </Button>

                    <Button
                      key="org"
                      onClick={handleOpenOrgMenu}
                      sx={{ my: 2, display: 'block' }}
                      style={{ ...lowerTabStyles, ...lastLowerTabStyle }}
                    >
                      <OrgIcon sx={{ ...tabIconStyle }} />
                      Organization
                      <DownIcon
                        style={{
                          ...downCaretStyle,
                          ...lowerNavDownCaretStyle,
                        }}
                      />
                    </Button>

                    <Menu
                      id="org-menu"
                      anchorEl={anchorElOrgMenu}
                      open={Boolean(anchorElOrgMenu)}
                      keepMounted
                      onClose={handleCloseOrgMenu}
                      onBlur={handleCloseOrgMenu}
                      hideBackdrop
                    >
                      {orgMenuItems.map((item) => {
                        return (
                          <MenuItem
                            onClick={(event) => {
                              event.stopPropagation();
                              navigate(item.url);
                              handleCloseOrgMenu();
                            }}
                            disabled={item.disabled}
                            className="nav-menu-item"
                          >
                            {item.label}
                          </MenuItem>
                        );
                      })}
                    </Menu>

                  </Box>

                </Toolbar>
              </AppBar>
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
        </Box>
        <Container maxWidth="xl">
          <Routes>
            <Route path="/dashboard" element={<HomeTemplate />} />

            <Route
              path="/"
              element={
                <InfectionControlSummary pdfHeaderImage={middleNavbarImage} />
              }
            />
            <Route
              path="/infection-safe/summary/map"
              element={
                <InfectionControlSummaryMap
                  pdfHeaderImage={middleNavbarImage}
                />
              }
            />
            <Route
              path="/infection-safe/consequences"
              element={
                <InfectionControlConsequences
                  pdfHeaderImage={middleNavbarImage}
                  currentLocation={currentLocation}
                />
              }
            />

            <Route
              path="/infection-safe/manage-case"
              element={<ManageCase />}
            />

            <Route path="/my-profile" element={<MyProfile />} />

            <Route
              path="/people"
              element={<PersonManagement
                tags={tags}
                fetchPeople={fetchPeople}
              />}
            />
            <Route
              path="/people/edit"
              element={<PersonEditor
                fetchTags={fetchTags}
                fetchPerson={fetchPerson}
                fetchRoleTypes={fetchRoleTypes}
                savePerson={savePerson}
              />}
            />
            <Route
              path="/people/edit/:id"
              element={<PersonEditor
                fetchTags={fetchTags}
                fetchPerson={fetchPerson}
                fetchRoleTypes={fetchRoleTypes}
                savePerson={savePerson}
              />}
            />

            <Route
              path="/assets"
              element={<AssetManagement
                tags={tags}
                fetchAssets={fetchAssets}
              />}
            />
            <Route
              path="/assets/edit"
              element={<AssetEditor
                fetchTags={fetchTags}
                fetchAsset={fetchAsset}
                fetchAssetTypes={fetchAssetTypes}
                saveAsset={saveAsset}
              />}
            />
            <Route
              path="/assets/edit/:id"
              element={<AssetEditor
                fetchTags={fetchTags}
                fetchAsset={fetchAsset}
                fetchAssetTypes={fetchAssetTypes}
                saveAsset={saveAsset}
              />}
            />

            <Route
              path="/application/settings"
              element={<ApplicationSettings />}
            />
            <Route
              path="/reports"
              element={<Reports pdfHeaderImage={middleNavbarImage} />}
            />
            <Route
              path="/reports/under-construction"
              element={<ReportUnderConstruction />}
            />
            <Route
              path="/infection-safe/show-outstanding-notifications"
              element={<ShowOutstandingNotifications />}
            />
          </Routes>
        </Container>
      </ThemeProvider>
    </>
  );
}

export default MainContainer;
